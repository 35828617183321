import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@core/services/environment.service';
import { ResponseUserGrantDto } from '@kerberos-compliance/lib-adp-shared/core/iam/authorization/dtos/response.user-grant.dto';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BaseAuthService {
  public constructor(
    protected readonly httpClient: HttpClient,
    protected readonly environmentService: EnvironmentService,
  ) {}

  /**
   * Retrieves the list of grants associated with the provided access token.
   *
   * @param {string} accessToken - The access token used for authorization.
   * @return {Observable<ResponseUserGrantDto[]>} An observable containing an array of UserGrant objects.
   */
  public getGrant(accessToken: string): Observable<ResponseUserGrantDto[]> {
    return this.httpClient.get<ResponseUserGrantDto[]>(`${this.environmentService.baseAdpHost}/grant/session`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }
}
