<div class="flex h-screen flex-col">
  <div class="mx-auto mb-12 pt-40">
    <app-logo></app-logo>
  </div>
  <app-content-wrapper variant="small" [center]="true" class="grow">
    <app-card bodyClass="p-6 text-neutral max-w-xs">
      <ng-container *transloco="let t">
        <h1 class="mb-4 text-lg font-bold leading-5">{{ t('logoutPage.sessionExpired') }}</h1>
        <p class="mb-10 text-sm">
          {{ t('logoutPage.reasonOfExpiration') }}
        </p>
        <app-button variant="primary" (click)="onBackToLoginClick()">
          {{ t('logoutPage.backToLogin') }}
        </app-button>
      </ng-container>
    </app-card>
  </app-content-wrapper>
</div>
